
//import firebase from 'firebase';
import firebase from 'firebase/compat'
import 'firebase/firestore'

// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


var firebaseApp = firebase.initializeApp ({
    apiKey: "AIzaSyAOMcVcEc0BSddX_j866yPhCxtPeung_aU",
    authDomain: "cvision-automotive-contact.firebaseapp.com",
    projectId: "cvision-automotive-contact",
    storageBucket: "cvision-automotive-contact.appspot.com",
    messagingSenderId: "171419756625",
    appId: "1:171419756625:web:ce25ef4751deb1c277b070"
  //  measurementId: "G-5L4LHS7EG5"
});


// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
var db = firebaseApp.firestore();
export { db };