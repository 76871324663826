import { React, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";
import ScrollToTop from './ScrollToTop.js';
import Home from './Pages/Home/Home.js';
import NotFound from './Pages/NotFound/NotFound.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Sass/style.css';
import ReactGa from 'react-ga';
//import react from 'react';
//import React, {useEffect} from 'react';
//import useGaTracker from './useGaTracker'

const App = () => {
//function App() {
    //useGaTracker();
    useEffect (() =>{
      ReactGa.initialize('UA-221608604-1')

      //to Report page view
      ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])

  AOS.init({
    once: false,
  });

  return (
    <div className="App">
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route path="/home">
                           <Home/>
                    </Route>
                    <Route exact path="/">
                           <Home/>
                    </Route>
                    <Route path="*">
                        <NotFound/>
                    </Route>
                </Switch>
            </ScrollToTop>
        </Router>
    </div>
  );
}

export default App;
